@import "modules/responsive-type.scss";
@import url('https://fonts.googleapis.com/css?family=Alfa+Slab+One|Encode+Sans+Expanded');

$primary: #c73f72;/* MAIN COLOR */
$secondary: #296199; /* SECONDARY COLOR */
$blk: #333; /* DARK GRAY, REASON WHY IT'S SET TO #333 IS SO YOU CAN DARKEN IT IF NEEDED USING darken($blk, 10%); FOR EXAMPLE. YOU COULD ALSO USE 2 VARIABLES INSTEAD */
$wht: #fafafa;
$border-radius: 0px; /* USE THIS TO SET THE BORDER RADIUS FOR BUTTONS */
$footerLinks: #fafafa;

// Set your maximum and minimum screen sizes.
$min_width: 320px;
$max_width: 1920px;

// Font sizes will range between the $min_font and $max_font.
$min_font: 15px;
$max_font: 22px;

// Change these values to change the "scale" between different headers
// (h1,h2,h3, etc.). Larger numbers = larger font-sizes. 

$mod_1: 1.2; // mobile
$mod_2: 1.5; // desktop

html, body {
    height: 100%;
}

html {
  @include fluid-type($min_width, $max_width, $min_font, $max_font);
}

p {
    font-size: 1rem;
}

h1 {  
  font-size: $mod_1*$mod_1*$mod_1*$mod_1 *1rem; 
  @include fluid-type($min_width, $max_width, $mod_1*$mod_1*$mod_1 *$min_font, $mod_2*$mod_2*$mod_2 *$min_font);
}
h2 {  
  font-size: $mod_1*$mod_1*$mod_1 *1rem; 
  @include fluid-type($min_width, $max_width, $mod_1*$mod_1*$mod_1 *$min_font, $mod_2*$mod_2*$mod_2 *$min_font);
}
h3 { 
  font-size: $mod_1*$mod_1 *1rem;
  @include fluid-type($min_width, $max_width, $mod_1*$mod_1 *$min_font, $mod_2*$mod_2 *$min_font);
}

body {
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
}

.flash {
    display: none;
}

q,
blockquote {
    quotes: "“" "”";
} 

.navbarFixed {
    position: fixed;
    width: 100%;
    top: 0;
    z-index: 999;
}

/* Change autocomplete styles in WebKit */

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus input:-webkit-autofill,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover textarea:-webkit-autofill:focus,
select:-webkit-autofill, 
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
    border: 1px solid #0065dd;
    -webkit-text-fill-color: #0065dd;
    -webkit-box-shadow: 0 0 0px 1000px #000 inset;
    transition: background-color 5000s ease-in-out 0s;
}

nav.navbar {
    background: $blk;
    z-index: 1000;
    border: none;
    border-radius: $border-radius;

    .navbar-nav {
        >li>a {
            font-family: 'Encode Sans Expanded', sans-serif;
            font-size: 20px;
            text-align: center;
            margin-top: 42px;
            display: flex;
            align-items: center;
            color: $wht;
            @media (max-width: 990px) {
                font-size: 16px;
                margin-top: 32px;
            }
            @media (max-width: 767px) {
                margin-top: 0;
                padding: 6px 0px;
                display: inline-block;
            }
            &:focus,
            &:active {
                background: transparent;
                color: $wht;
                outline: 0;
            }
            &:hover {
                background: $primary;
                color: $wht;
                @media (max-width: 1024px) {
                    background: transparent;
                    color: $wht;
                    outline: 0;
                }
            }
        }
    }
}

.navbar-right {
    margin-top: 0px;
}

.navbar-toggle {
    margin: 20px 15px 8px 0px;
    border: 1px solid transparent;
    border-radius: 4px;
}


/** LOGIN FORM **/

@mixin btn {
    border: none;
    border-radius: $border-radius;
    background: $primary;
    color: $wht;
    padding: 0.5em 2em;
    font-size: 1.25em;
    font-weight: bold;
    text-align: center;
    text-transform: uppercase;
    letter-spacing: 1px;
    display: inline-block;
    margin: 0.5em 0em;
    transition: 0.7s ease all;
    &:hover {
        background: lighten($primary, 10%);
        color: lighten($wht, 10%);
        text-decoration: none;
        @media (max-width: 1024px) {
            background: $secondary;
            color: $wht;
            box-shadow: 0px 0px 5px $blk;
        }
    }
}

.btn-default {
    @include btn;
}

@mixin btn2 {
    border: none;
    border-radius: $border-radius;
    background: $primary;
    color: $wht;
    padding: 0.5em 2em;
    font-size: 1.25em;
    font-weight: bold;
    text-align: center;
    text-transform: uppercase;
    letter-spacing: 1px;
    display: inline-block;
    transition: 0.7s ease all;
    position: sticky;
    z-index: 9;
    overflow: hidden;
    &:hover {
        background: lighten($primary, 10%);
        color: lighten($wht, 10%);
        text-decoration: none;
        @media (max-width: 1024px) {
            background: $primary;
            color: $wht;
            box-shadow: 0px 0px 5px $blk;
        }
    }
    &:focus, &:active {
        background: $primary;
        color: $wht;
    }
}

.btn-default2 {
    @include btn2;
}

.modal-dialog {
    max-width: 300px;
    text-align: center;
    margin: 6em auto;
    .close {
        display: none;
    }
    .modal-content {
        color: $primary;
        h2 {
            text-align: center;
        }
    }
    input {
        border: 1px solid #777;
        text-align: center;
    }
    input#username,
    input#password {
        @media (max-width: 1024px) {
            font-size: 16px;
        }
    }
    button {
        background: transparent;
        color: $wht;
        display: block;
        border: none;
        padding: 1em 2em;
        margin: 0 auto;
        font-size: 18px;
        &:hover {
            background: transparent;
            color: #fff;
            box-shadow: none;
            text-shadow: 0px 0px 3px $blk;
            border: none;
        }
    }
    input[type="submit"] {
        @include btn;
        display: block;
        width: 75%;
        font-size: 18px;
        margin: 0 auto;
    }
}

.modal-header,
.modal-footer {
    background: $primary;
    color: $wht;
}

.modal-open {
    overflow: auto;
    padding-right: 0px !important;
}

input#username {
    margin-bottom: 20px;
}


/** END LOGIN FORM **/

footer {
    padding: 50px 0px 20px;
    background: $primary;
    color: $footerLinks;
    font-size: 15px;
    a {
        color: $footerLinks;
        white-space: nowrap;
        font-size: 15px;
        @media (max-width: 990px) {
            font-size: 13px;
        }
        &:hover {
            color: lighten($footerLinks, 10%);
            outline: 0 !important;
            text-decoration: none;
        }
        &:focus {
            color: $wht;
            outline: 0 !important;
            text-decoration: none;
        }
    }
    p {
        font-size: 15px;
        @media (max-width: 990px) {
            font-size: 13px;
        }
    }
}

input#username,
input#password {
    width: 100%;
    @media (max-width: 1024px) {
        font-size: 16px;
    }
}

select {
    -moz-appearance: none;
    text-indent: 0.01px;
    text-overflow: '';
}

a,
a:hover,
a:focus,
a:active {
    outline: 0 !important;
}

.logo {
    max-width: 450px;
    padding: 0.5em;
}

@media (max-width: 990px) {
    .logo {
        max-width: 255px;
        margin-top: 20px;
    }
}

@media(max-width: 767px) {
    .logo {
        max-width: 265px;
        margin-top: 5px;
    }
    .navbar-header a {
        float: left;
    }
    .navbar-toggle {
        margin-top: 25px;
    }
}

@media (max-width: 600px) {
    .logo {
        max-width: 215px;
        margin-top: 0;
    }
    .navbar-toggle {
        margin-top: 18px;
    }
}

.top-pad {
    padding: 3em;
    background: white;

    @media (max-width: 990px) {
        padding: 1em;
    }    
}

.flex {
    display: flex;
    justify-content: center;
    align-items: center;
    @media  (max-width: 1024px) {
        display: block;
        /* may need to be inline-block */ 
    }
}

.terms,
.privacy {
        font-family: 'Encode Sans Expanded', sans-serif;
        font-size: 1rem;
    
    & ol {
        li {
            font-size: 1rem;
        }
        & ol li {
            font-size: 0.85rem;
        }
    }
}

/* CLOUDS HEADER */

/*----------------------------------
    Main
----------------------------------*/

#wrapper {
    position: relative;
    width: 100%;
    height: 100%;

    @media (max-width: 1024) {
        height: 125%;
    }

    @media (max-width: 990px) and (orientation: landscape) {
        height: 150% !important;
    }

    @media (max-width: 767px) and (orientation: landscape) {
        height: 200% !important;
    }
}

.header {
    background: #64B1EE;
    width: 100%;
    height: 100%;
    position: absolute;
    overflow: hidden;
}



/*----------------------------------
    Cloud
----------------------------------*/
.cloud,
.cloud:before,
.cloud:after {
    content: "";
    background: #D2EFFF ;
    display: block; 
    position: absolute;
    left: 5%;
    top: 15%;
    border-radius: 100%;
    width: 170px;
    height: 60px;
    z-index: 1;
}
.cloud:before {
    width: 125px;
    height: 65px;
    top: -22px;
    border-radius: 50%;
    left: 24px;
}
.cloud:after {
    width: 58px;
    height: 48px;
    top: -35px;
    left: 57px;
}
.cloud:nth-child(1) {
    -webkit-animation: cloud 7s linear  infinite;
    -o-animation: cloud 7s linear  infinite;
    animation: cloud 7s linear  infinite;
}
.cloud:nth-child(2) {
    left: 45%;
    top: 40%;
    z-index: 0;

    /* transform */
    -webkit-transform: scale(1.8);
    -ms-transform: scale(1.8);
    -o-transform: scale(1.8);
    transform: scale(1.8);

    /* animation */
    -webkit-animation: cloud_b 6s linear infinite;
    -o-animation: cloud_b 6s linear infinite;
    animation: cloud_b 6s linear infinite;
}
.cloud:nth-child(3) {
    left: 62%;
    top: 25%;

    /* transform */
    -webkit-transform: scale(1.3);
    -ms-transform: scale(1.3);
    -o-transform: scale(1.3);
    transform: scale(1.3);

    /* animation */
    -webkit-animation: cloud_a 7s linear infinite;
    -o-animation: cloud_a 7s linear infinite;
    animation: cloud_a 7s linear infinite;
}
.cloud:nth-child(4) {
    left: 25%;
    top: 40%;

    /* transform */
    -webkit-transform: scale(1.5);
    -ms-transform: scale(1.5);
    -o-transform: scale(1.5);
    transform: scale(1.5);

    /* animation */
    -webkit-animation: cloud_d s linear infinite;
    -o-animation: cloud_d 8s linear infinite;
    animation: cloud_d 8s linear infinite;
}

.cloud:nth-child(5) {
    left: 75%;
    top: 40%;

    /* transform */
    -webkit-transform: scale(1.5);
    -ms-transform: scale(1.5);
    -o-transform: scale(1.5);
    transform: scale(1.5);

    /* animation */
    -webkit-animation: cloud_c 4s linear infinite;
    -o-animation: cloud_c 4s linear infinite;
    animation: cloud_c 4s linear infinite;
}

/*----------------------------------
    Airplane
----------------------------------*/
.airplane {
    position: absolute; 
    left: 40%;
    top: 20%;
    z-index: 0;
    -webkit-animation: planefly 10s linear infinite;
    -o-animation: planefly 10s linear infinite;
    animation: planefly 10s linear infinite;
}
.airplane div {
    background: #F9FBFC;
    border-radius: 100%;
    width: 60px;
    height: 60px;
    position: absolute;
    z-index: 1;
}
div.head  {
    top: 21px;
    left: 83px;
    width: 60px;
    height: 25px;
    border-radius: 100%;
}
div.body  {
    top: 20px;
    left: 0;
    width: 130px;
    height: 26px;
    border-radius: 40% 30% 20% 50%;
    z-index: 1
}
div.lwing {
    top: 7px;
    left: 60px;
    height: 21px;
    width: 30px;    
    border-radius: 5px; 
    z-index: 0 ;
    -webkit-transform: skew(51deg, 0deg);
    -ms-transform: skew(51deg, 0deg);
    -o-transform: skew(51deg, 0deg);
    transform: skew(51deg, 0deg);
}
div.rwing {
    top: 34px;
    left: 57px;
    height: 27px;   
    width: 35px;
    border-radius: 5px;
    z-index: 1; 
    box-shadow: 0px 6px 4px rgba(0, 0, 0, 0.16);
    -webkit-transform: skew(-49deg, 0deg);
    -ms-transform: skew(-49deg, 0deg);
    -o-transform: skew(-49deg, 0deg);
    transform: skew(-49deg, 0deg);
}
div.tale  {
    top: 15px;
    left: 10px;
    width: 16px;
    height: 16px;
    border-radius: 2px;
    -webkit-transform: skew(35deg, -9deg);
    -ms-transform: skew(35deg, -9deg);
    -o-transform: skew(35deg, -9deg);
    transform: skew(35deg, -9deg);
    background: linear-gradient(0deg,#FFF, #BBDEFF);
}
div.window,
div.window:before,
div.window:after {
    content: "";
    top: 6px;
    left: 48px;
    width: 4px; 
    height: 4px; 
    border-radius: 30%;
    background: #9AD0F5;
    border: 1px solid #5093D1;
    position: absolute;
}
div.window:before {
    left: -12px;
    top: -1px;
}
div.window:after {
    left: 10px;
    top: -1px;
}
div.window:nth-child(1){
    left:81px
}
div.window:nth-child(2){
    left:115px
}
div.window:nth-child(2):after {
    border-top-right-radius: 8px;
    width: 6px;
}


/*----------------------------------
    Animation
----------------------------------*/
@-webkit-keyframes planefly { 
    0% {
        left: -10%; 
        -webkit-transform: scale(.4);
        -ms-transform: scale(.4);
        -o-transform: scale(.4);
        transform: scale(.4);
    } 
    50% {
        left: 110%; 
        -webkit-transform: scale(1);
        -ms-transform: scale(1);
        -o-transform: scale(1);
        transform: scale(1);
    } 
    51% {
        -webkit-transform: rotateY(180deg);
        -webkit-transform: rotateY(180deg);
        -ms-transform: rotateY(180deg);
        -o-transform: rotateY(180deg);
        transform: rotateY(180deg);
    }
    100% {
        left: -10%; 
        -webkit-transform: scale(1.4) rotateY(180deg);
        -ms-transform: scale(1.4) rotateY(180deg);
        -o-transform: scale(1.4) rotateY(180deg);
        transform: scale(1.4) rotateY(180deg);
    }
}
@-webkit-keyframes cloud   { 
    0% { top: 120%; } 
    100% { top: -40%; }
}
@-webkit-keyframes cloud_a { 
    0% { top: 140%; } 
    100% { top: -30%; }
}
@-webkit-keyframes cloud_b { 
    0% { top: 120%; } 
    100% { top: -30%; }
}
@-webkit-keyframes cloud_c { 
    0% { top: 120%; } 
    100% { top: -30%; }
}
@-webkit-keyframes cloud_d { 
    0% { top: 120%; } 
    100% { top: -30%; }
}

@-webkit-keyframes swing   { 
    0% { transform: rotate(-2deg); }
    50% { transform: rotate(15deg); } 
    100% { transform: rotate(-2deg); }
}

.couple {
    display: block;
    margin: auto;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    animation: swing 3s infinite;

    @media (max-width: 1024px) {
        width: 75%;
        margin-top: 240px;
    }

    @media (max-width: 767px) {
        width: 75%;
        margin-top: 350px;
    }
}

.headline {
    font-family: 'Alfa Slab One', sans-serif;
    color: $secondary;
    text-shadow: 1px 2px 2px $wht;
    position: relative;
    z-index: 9;
    @include fluid-type($min_width, $max_width, 50px, 80px);

    @media (max-width: 1024px) {
        margin-top: 35px;
    }
}

.color {
    background: $secondary;
    padding: 40px 0;
}

.color2 {
    background: $primary;
    padding: 40px 0;
}

.color3 {
    background: darken($primary,10%);
    padding: 40px 0;
}

.subhead {
    font-family: 'Alfa Slab One', sans-serif;
    color: $wht;
    text-align: center;
}

.featTitle {
    font-family: 'Encode Sans Expanded', sans-serif;
    color: $wht;
    text-align: center;
    @include fluid-type($min_width, $max_width, 20px, 30px);
}

.featTxt {
    font-family: 'Encode Sans Expanded', sans-serif;
    color: $wht;
    text-align: center;
    @include fluid-type($min_width, $max_width, 14px, 16px);
}

i {
    color: $wht;
}

.text {
    font-family: 'Encode Sans Expanded', sans-serif;
    color: $wht;
    @include fluid-type($min_width, $max_width, 14px, 16px);
}

.radius {
    border-top-left-radius: 20px;
    border-bottom-right-radius: 20px;
}

.outro {
    background: url('../img/img1.jpg');
    background-size: cover;
    padding: 375px 0;
    background-position: 45% center;

    @media (max-width: 767px) {
        padding: 225px 0;
    }

    @media (max-width: 550px) {
        padding: 115px 0;
    }
}

.outroTitle {
    font-family: 'Alfa Slab One', sans-serif;
    text-align: center;
    text-shadow: 1px 1px 6px $primary;
    color: $wht;
    -webkit-text-stroke-color: $secondary;
    -webkit-text-stroke-width: 3px;
    @include fluid-type($min_width, $max_width, 40px, 70px);

    @media (max-width: 550px) {
        text-shadow: 1px 1px 3px $primary;
        -webkit-text-stroke-color: $secondary;
        -webkit-text-stroke-width: 2px;
    }
}

.push {
    margin-top: 50px;
}